import React from "react";
import logo from "../logo512.png";
import downloadios from "../assets/download_ios.png";
import downloadandroid from "../assets/download_play.png";
import "../App.css";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div style={{ maxWidth: "100vmin", padding: "16px" }}>
          <p>
            🌟 Dive into award season with Award Party! Join friends, vote on
            nominees, and see who tops the leaderboard. It's your party—make it
            epic! 🏆🎉"
          </p>
        </div>
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/award-party/id6476841113"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={downloadios}
            className="download-button"
            alt="Download for iOS"
          />
        </a>
        <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=com.award_ace.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={downloadandroid}
            className="download-button"
            alt="Download for Android"
          />
        </a>
      </header>
    </div>
  );
}

export default Home;
