import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "./home/home";
import Privacy from "./privacy_policy/privacy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },
  {
    path: "privacy",
    element: <Privacy></Privacy>,
  },
]);
