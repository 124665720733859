import React from "react";
import logo from "../logo512.png";
import "../App.css";

function PrivacyPolicy() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Award Party logo" />
        <h1>Privacy Policy</h1>
      </header>
      <main className="App-content">
        <h2>Your Privacy Matters</h2>
        <p>
          At Award Party, we respect your privacy and are committed to
          protecting the personal information you share with us. This policy
          outlines our practices regarding data collection, usage, and security.
        </p>
        <h3>Information We Collect</h3>
        <p>
          The only personal information we collect is what you provide to us:
          your name and email address. This information is essential for
          creating your account and enabling the app's functionality.
        </p>
        <h3>How We Use Your Information</h3>
        <p>
          Your personal information is used for personalization purposes within
          the app. We store your data securely in Firestore services, which
          provide end-to-end encryption to ensure your privacy.
        </p>
        <p>
          By using Award Party, you are also subject to{" "}
          <a
            className="App-link"
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firebase's privacy policy
          </a>
          , which governs the use of their services.
        </p>
        <h3>Your Choices and Controls</h3>
        <p>
          We believe you should have control over your personal information. You
          can manage your account details and email preferences at any time.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions about our privacy practices or this policy,
          please contact us at privacy@awardparty.app.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download for iOS
        </a>
      </main>
    </div>
  );
}

export default PrivacyPolicy;
